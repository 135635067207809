.skills-tools {
  padding: 0px 0 21px 0;
}
.skills-tools h1 {
  font-size: 40px;
}
.tappanel-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 7px;
}
.tab-list-skills {
  list-style-type: none;
  border-radius: 9999px;
  display: inline-block;
  width: auto;
  background-color: #252525;
  padding: 4px;
  position: relative;
}
.tab-skills {
  float: left;
  text-align: center;
  border-radius: inherit;
  padding: 0.5em;
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
  width: 120px;
}
.selected-tab-skills {
  background-color: orange;
}

.skills-ul,
.tab-list-skills,
.blog-tab-list {
  list-style-type: none;
  padding: 0;
}
.skills-ul li {
  width: auto;
  padding: 5px;
}
.img-skill {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  background-color: #252525;
  width: 120px;
}
.img-skill img {
  width: 100px;
  height: 90px;
}
.img-skill h6 {
  font-size: 14px;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .skills-ul li {
    padding: 4px;
  }
  .img-skill {
    padding: 6;
    width: 100px;
  }
  .img-skill h6 {
    font-size: 12px;
  }
  .img-skill img {
    width: 62px;
    height: 62px;
  }
  hr.separator {
    margin: 28px auto 28px;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .skills-ul li {
    padding: 3px;
  }
  .img-skill {
    padding: 5;
    width: 100px;
  }
  .img-skill h6 {
    font-size: 12px;
  }
  .img-skill img {
    width: 62px;
    height: 62px;
  }
  hr.separator {
    margin: 26px auto 26px;
  }
}
