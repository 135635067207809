// .herotitle {
//   background: linear-gradient(90deg, #000, #fff, #000);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-repeat: no-repeat;
//   background-size: 80%;
//   animation: shine 5s linear infinite;
// }

// @keyframes shine {
//   0% {
//     background-position-x: -500%;
//   }
//   100% {
//     background-position-x: 500%;
//   }
// }

// scroll to in home page
.home-details-container {
   height: auto;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .aboutme-button{
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .social-home {
    margin-bottom: 3em;
  }
  .home .home-details p {
    margin: 10px 0 5px;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .social-home {
    margin-bottom: 1em;
  }
  .home .home-details p {
    margin: 10px 0 5px;
  }
}
