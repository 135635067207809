.blog-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 1.5em; /* fallback */
  max-height: 3em; /* fallback */
}

.code-blog,
xmp {
  padding: 0;
  margin: 0;
}
.code-tag-html-file {
  padding: 0;
  margin: 0;
  overflow-x: auto;
}
// .blog .post-thumb {
//   min-height: auto;
// }
