.portfolio,
#root {
  height: 100%;
}
.portfolio .grid figure img {
  height: 229px;
  width: auto;
}
.videocontainer {
  background-color: black;
}
